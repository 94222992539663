import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useQuery } from 'react-apollo';

import query from '../../../../api/export-space-data-in/query';

const Page: FC<PageProps> = ({ params: { id } }) => {
  const { data, error, loading } = useQuery(query, {
    variables: {
      id,
    },
  });

  if (error || loading) {
    return null;
  }

  return <pre>{JSON.stringify(data.spacesExport, null, 2)}</pre>;
};

export default Page;
